@import '../../../responsive-nlc';

.appNexusWrapper {
  max-width: 428px;
  height: 100%;
  background-color: var(--lcui-whiteSoft);
  border-radius: var(--radius-medium);
  background-image: url('../../../assets/watermark_logo_lc.svg'),
    url('../../../assets/publicite.png');
  background-repeat: no-repeat, no-repeat;
  background-position:
    50% 40%,
    50% 50%;

  @include respond-to-large-tablet {
    background-position:
      50% 40%,
      50% 60%;
    max-width: 100%;
  }

  > div {
    height: 100%;
  }

  // Pavé instead of NativeAd
  &:has(iframe) {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      height: fit-content;
    }
  }
}

.appNexusPlaceholder {
  @include respond-to-large-tablet {
    height: 167px;
  }

  &:has(iframe) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* NativeAd content set in commandersAct */
.nativeAd {
  height: 100%;
  box-shadow: var(--elevation-1);
  background: var(--lcui-white);
  border-radius: var(--radius-medium);
  overflow: hidden;
  cursor: pointer;

  &__cta {
    text-align: center;
    text-decoration: underline;
    border-top: 1px solid var(--lcui-greyLight);
    margin-top: auto !important;
    padding: var(--spacing-xsmall) 0;
    font-weight: 600;
  }

  &__link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    height: 100%;
    font-size: 14px;
    line-height: 20px;

    @include respond-to-large-tablet {
      flex-wrap: wrap;
      align-items: start;
      overflow: hidden;

      .nativeAd__sponsoredBy,
      .nativeAd__title,
      .nativeAd__description,
      .nativeAd__cta {
        width: calc(100% - (224px + 2 * var(--spacing-small)));
      }
    }

    &:hover .nativeAd__cta {
      color: var(--component-links-default-primary-hover, #ff004f) !important;
      transition: color 0.3s;
    }
  }

  &__image {
    text-align: center;
    background-color: var(--colors-secondary-weak);
    aspect-ratio: 15 / 8.7;
    max-height: 210px;
    width: 100%; // mandatory safari for text-align center to work

    /* stylelint-disable-next-line no-descending-specificity */
    img {
      aspect-ratio: 16 / 9;
      object-fit: contain;
      height: 100%;
    }

    @include respond-to-large-tablet {
      width: 224px;
      aspect-ratio: 4 / 3;
    }
  }

  &__sponsoredBy {
    font-size: 12px;
    line-height: 16px;
    color: var(--colors-secondary-xsoft);
    margin: var(--spacing-xsmall) var(--spacing-small);
  }

  &__title,
  &__description,
  &__cta {
    margin: 0 var(--spacing-small);
    color: var(--colors-secondary);
  }

  &__title {
    margin-bottom: var(--spacing-xxsmall);
  }

  &__description {
    margin-bottom: var(--spacing-xsmall);
  }
}
