@import '../../responsive-nlc';

.propulse-card {
  background: var(--colors-negative, #fff);
  cursor: pointer;
  border-radius: var(--radius-medium);
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: var(--elevation-1);

  // Reference ton vehicleCard
  min-width: 288px;
  max-width: 428px;

  @include respond-to-large-tablet() {
    flex-direction: row;
    min-width: 321px;
    height: 167px;
    max-width: none;
  }

  &__header {
    height: 171px;
    background-color: var(--colors-secondary-weak);

    @include respond-to-large-tablet() {
      width: 224px;
    }

    picture {
      width: 100%;
      height: 100%;
      display: block;
    }

    img {
      max-height: 100%;
      margin: 0 auto;
      display: block;
    }
  }

  &__content {
    padding: var(--spacing-xsmall) var(--spacing-small);
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--spacing-xxsmall);

    &--cta-wrapper {
      margin-top: auto;
      text-align: center;

      &:hover button {
        color: var(--component-links-default-primary-hover, #ff004f);
      }
    }
  }

  &__stocks {
    display: flex;
    gap: var(--spacing-small);
    align-items: center;

    hr {
      margin: 0;
    }
  }

  &__brands {
    &--wrapper {
      display: flex;
      gap: var(--spacing-xsmall);
    }

    &--content {
      width: 20px;
      height: 20px;
      padding: 2px;
      border: 1px solid var(--colors-secondary-xxxsoft, #d4d4de);
    }

    picture {
      width: 100%;
      height: 100%;
      display: block;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
