/* Animation wiggle bell */
@keyframes wiggle {
  0%,
  90% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }

  92% {
    transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
  }

  94% {
    transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
  }

  96% {
    transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
  }

  98% {
    transform: rotate(-5deg);
    -webkit-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
}

.save-filter-button {
  position: sticky;
  display: flex;
  margin: 0 auto;
  bottom: 20px;
  left: 0;
  transition: 350ms ease-in-out !important;
  z-index: 10;
  overflow: hidden;

  .Icon_Icon_square {
    animation: wiggle 3s infinite;
  }
}
