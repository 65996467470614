@import '../../../responsive-nlc';

.distance-popin__body {
  @include respond-to-mobile() {
    .cbm--onlyDesktop {
      display: none;
    }
  }

  @include respond-to-desktop-and-tablet() {
    .cbm--onlyMobile {
      display: none;
    }
  }

  .distance--popin {
    &-geolocationButtons {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.zipcode_input {
  margin-top: 10px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
