@import '../../../responsive-nlc';

.container {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  padding-top: 32px;

  .paginationCount {
    text-align: center;
    margin-bottom: 16px;
  }

  &.pagination {
    &__desktop {
      @include not-desktop {
        display: none;
      }
    }

    &__mobile {
      @include respond-to-desktop {
        display: none;
      }
    }
  }
}
