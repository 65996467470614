@import '../../responsive-nlc';
@import '../index';

:root {
  --margin-title-bottom-mobile: 6px;
  --margin-title-right-mobile: 15px;
  --margin-title-bottom-desktop: 10px;
  --margin-title-right-desktop: 32px;
  --margin-container-bottom-mobile: 14px;
  --margin-container-bottom-desktop: 22px;
}

.containerSite,
.containerContent {
  clear: both;
  position: relative;

  @include respond-to-desktop {
    max-width: 1200px;
  }
}

[class*='claimslanding_claimsLanding'] {
  overflow: visible;
}

.claimsLanding-container {
  > .Tabs_Tabs_roundedTab {
    margin-bottom: 16px;
  }
}

.sellOrBuyEntries {
  margin-bottom: 16px;
}
