.checkbox-tree {
  padding-left: 36px;
  padding-bottom: 16px;

  &__mobile {
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 12px;
  }
}
