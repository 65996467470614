.color-horizontal-multiSelect {
  display: flex;
  color: var(--lcui-greyDark);
  column-gap: 10px;
  padding: 4px 8px 4px 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(0 0 0 / 11%);
    border-radius: 5px;
  }
}
