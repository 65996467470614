@import '../../responsive-nlc';

.filters-block {
  padding-left: 16px;
  max-width: 283px;

  @include respond-to-large-tablet {
    flex: 0 0 283px;
  }
}

.listing-block {
  position: relative;
  height: 100%;

  @include respond-to-tablet {
    background-color: var(--colors-secondary-weak);
  }

  @include not-desktop {
    padding-bottom: 16px;
  }
}

@include respond-to-large-tablet {
  @include not-desktop {
    .advertising-container,
    .pagination__mobile {
      background-color: var(--colors-secondary-weak);
      margin: 0 16px;
    }
  }
}

@include respond-to-tablet() {
  .advertising-container {
    display: none;
  }
}

.advertissing {
  background-color: var(--lcui-whiteSoft);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond-to-desktop {
    min-height: 904px;
    height: 100%;
    border-radius: var(--radius-medium);
  }

  @include not-desktop {
    width: 300px;
    height: 270px;
    margin: 20px auto;
  }

  .pubLabel {
    margin-bottom: 4px;
  }
}
