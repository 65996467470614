@import '../../../responsive-nlc';

/* Localisation et tri */
.listing-header-container {
  margin: 0 var(--spacing-medium);
  padding-top: var(--spacing-medium);
}

.listing-page__classified-ad-Header {
  @include respond-to-large-tablet {
    display: none;
  }

  @include respond-to-tablet {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: var(--spacing-xsmall);
  }
}

.listing-header__title {
  max-width: 60%;

  @include respond-to-tablet {
    max-width: 100%;
  }
}

.mediation-link {
  word-break: break-word;
}

.titleNbAds {
  display: flex;
  align-items: flex-end;
  gap: 4px;

  &__button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
}

.divider {
  display: none;

  &__resultcount {
    margin-bottom: var(--spacing-large);
    margin-top: var(--spacing-small);
  }

  @include respond-to-tablet {
    display: block;
    margin: 20px 15px 8px;
    width: inherit;
  }
}

.filtrerEtTrier {
  display: none;

  @include respond-to-tablet {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: var(--lcui-white);
    width: 100%;
    padding: 8px 16px;
  }
}

.sticky-block {
  @include respond-to-tablet {
    left: 0;
    position: sticky;
    z-index: 999;
    top: 0;
  }
}

.filter-button {
  display: none;

  @include respond-to-tablet {
    display: flex;
    width: 50%;
    text-align: center;
  }
}

.total {
  color: var(--lcui-primary);
}

.first-part-of-title {
  @include respond-to-desktop {
    max-width: 95%;
  }
}
