@import '../../responsive-nlc';

.searchForm {
  .search-button {
    z-index: 10;
    display: flex;
    align-items: center;
    bottom: 0;
    background-color: var(--lcui-white);
    padding: 12px 16px;
    box-shadow: 0 -1px 4px 0 var(--colors-elevation-1, rgb(109 109 131 / 25%));
    text-align: center;
    margin-top: 1px;
    gap: 10px;
    width: calc(100% + 32px);
    transform: translateX(-16px);

    &__search {
      white-space: break-spaces;
      margin: 0 auto;
      width: 100%;
    }
  }

  &.searchEngineListing {
    @include respond-to-tablet {
      display: none;
    }

    .search-button {
      position: sticky;

      &.showroomMode {
        justify-content: center;
        padding: 9px 24px;
        margin-right: -16px;
      }
    }
  }

  &.filterPage {
    position: absolute;
    left: 0;
    width: 100%;

    .search-button {
      position: fixed;
      width: 100%;
      padding: 12px 16px;
      gap: 0;
      justify-content: center;
      transform: none;

      &.showroomModeinFilterPage {
        padding: 10px 30px;
      }
    }

    .withSubmitOffset {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-small);
      padding: var(--spacing-small) var(--spacing-small) 120px var(--spacing-small);
      background: var(--colors-secondary-weak, #f4f4f7);
    }
  }

  &:not(.filterPage) .withSubmitOffset {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
    border-top-left-radius: var(--radius-medium);
    border-top-right-radius: var(--radius-medium);
    padding: var(--spacing-small);
    background: var(--colors-secondary-weak, #f4f4f7);
  }
}

.divider-filters {
  margin: var(--spacing-medium) 0;
}
