@import '../responsive-nlc';

/* SearchEngine -  Logos Categories (auto, moto, utils) */
.Controls_Controls_left label {
  text-align: left;
}

.containerContent {
  background-color: var(--lcui-white);
  box-sizing: border-box;

  @include respond-to-desktop {
    max-width: 1200px;
  }
}

.containerSite {
  margin: 0 auto;
  clear: both;
  position: relative;
}

.CBM_page_listing .Modal_Modal_modal {
  overflow: auto !important;
}

.cbm-mainColumn {
  margin: 0;
  padding: 0;
  width: 100% !important;
}

.mainCol {
  padding: 0 !important;
  width: 100% !important;
}

.mainListing {
  display: flex;

  @include respond-to-tablet {
    flex-direction: column;
    align-items: center;
  }
}

@include respond-to-desktop {
  .stickyAds {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 72px;
    margin-bottom: 10px;
  }
}

//Trier par
.sortCombo {
  border: none;
  cursor: pointer;
  width: 100% !important;
  padding-right: 20px;

  @include respond-to-tablet {
    display: none;
  }

  &::after {
    display: none;
  }

  &::before {
    display: none;
  }
}

///// DEBUT TRIER
//Dropdown Trier par
.selectFieldContainer,
.SelectFieldContent {
  max-height: 100% !important;
}

.SelectFieldContent__optionContainer {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--lcui-black);
  height: fit-content !important;
  padding-top: 12px !important;

  &-desktop {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
}

//Bouton filtrer dans le listing
.filter-button.sticky {
  display: none;

  @include respond-to-tablet {
    max-width: 428px;
    transition: all 0.5s ease;
    margin-top: 20px;
    margin-bottom: 60px;
    background-color: transparent !important;
    display: block !important;
    position: sticky;
    bottom: 24px;
    text-align: center;
    z-index: 10;
    left: 50%;
    transform: translate(-50%);
  }

  @include respond-to-mobile {
    left: inherit;
    top: inherit;
    transform: none;
    max-width: 330px;
    width: 100%;
    margin: 20px auto 60px;
  }
}

.expand {
  padding: 16px 0;

  &__open {
    + div {
      margin-bottom: 4px;
    }

    + div div:first-child div:first-child label:first-child {
      padding-top: 0 !important;
    }
  }
}

.sortDropdown {
  position: absolute;
  z-index: 11;
  width: 100%;
  padding-right: 16px;
  right: 0;
}

///// FIN TRIER

/// DELIVERY
.modal-information {
  @media screen and (width <= 530px) {
    max-width: 100% !important;
  }
}

.modal-body {
  text-align: justify;

  &_link-list {
    list-style: outside;
    margin-left: 2rem;
    word-wrap: break-word;
  }

  &_link {
    word-wrap: break-word;

    &:hover {
      color: var(--lcui-primary--400);
    }
  }
}

.slider-container {
  margin-top: -20px;

  &__text {
    padding-top: 32px;
    display: flex;
    justify-content: space-between;

    &__label {
      text-align: left;
    }

    &__distance {
      text-align: left;
    }
  }
}

.sliderWithStep .rangeslider__labels .rangeslider__label-item {
  font-size: 12px;
  white-space: nowrap;

  &:nth-child(1) {
    padding-left: 10px;

    @include respond-to-large-tablet() {
      padding-left: 3px;
    }
  }

  &:nth-child(2) {
    padding-left: 20px;

    @include respond-to-large-tablet() {
      padding-left: 8px;
    }
  }

  &:nth-child(3) {
    padding-left: 20px;

    @include respond-to-large-tablet() {
      padding-left: 8px;
    }
  }

  &:nth-child(4) {
    padding-left: 20px;

    @include respond-to-large-tablet() {
      padding-left: 8px;
    }
  }
}

.carrousel-fallback {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 578px;

  @include respond-to-tablet {
    height: 420px;
  }
}
