.legalMentionModalContent {
  overflow: auto;

  p {
    margin-bottom: 12px;
    padding-right: 8px;
  }
}

.legalMentionLoader {
  margin: auto;
}
