@import '../../../../responsive-nlc';

/* Bloc contenant le tri */
.sortDesktopContainer {
  width: fit-content;
  min-width: 280px;
  margin: 0 0 var(--spacing-xsmall) auto;

  @include respond-to-tablet {
    display: none;
  }

  @include respond-to-mobile {
    display: none;
  }
}
