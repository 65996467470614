@import '../../../responsive-nlc';

$ad-image-width: 195px;
$ad-image-max-height: 114px;

.nmpAdWrapper {
  display: none;
  padding: 0 var(--spacing-medium) var(--spacing-medium);
}

.nmpAdWrapper__divider {
  margin-top: var(--spacing-small);
  margin-bottom: var(--spacing-xxlarge);
}

.nmpAdWrapper__title {
  margin-bottom: var(--spacing-medium);
}

.nmpAdWrapper__content {
  display: flex;
  gap: var(--spacing-medium);
  justify-content: space-between;
}

.nmpAdWrapper__content > div {
  width: 50%;
}

.nmpAdWrapper__content > div > div {
  height: 100%;
  min-height: 134px;
}

/* Nmp content set in commandersAct */
.nmp {
  box-shadow: var(--lcui-shadow--9);
  border-radius: var(--borderRadius);
  background: var(--lcui-white);
  height: 100%;
  padding: var(--spacing-small);
}

.nmp__cta {
  grid-column: 1 / span 2;
  grid-row: 3 / 4;
  color: var(--lcui-primary);
  border-top: 1px solid var(--lcui-greyLight);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  padding-top: var(--spacing-xsmall);
  text-decoration: underline;
  transition: 150ms ease color;
}

.nmp__link {
  color: var(--lcui-black);
  display: grid;
  grid-gap: var(--spacing-small);
  grid-template-columns: $ad-image-width auto;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  cursor: pointer;
  text-decoration: none;

  &:hover .nmp__cta {
    color: var(--component-links-default-primary-hover, #ff004f) !important;
  }
}

.nmp__image {
  grid-column: 1;
  grid-row: 1 / 3;
  width: $ad-image-width;
  height: $ad-image-max-height;
  border-radius: var(--borderRadius);
  overflow: hidden;
  background-color: var(--colors-secondary-weak);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nmp__image > img {
  max-height: $ad-image-max-height;
}

.nmp__title {
  grid-column: 2;
  grid-row: 1 / 2;
  font-weight: 600;
  font-size: 14px;
}

.nmp__description {
  grid-column: 2;
  grid-row: 2 / 3;
  font-size: 14px;
  line-height: 20px;
}

@include respond-to-tablet {
  .nmpAdWrapper__content {
    display: flex;
    flex-direction: column;
  }

  .nmpAdWrapper__content > div {
    width: 100%;
  }
}
