@import '../../../responsive-nlc';

.searchCardsAndPagination--lre {
  display: none;
  padding: var(--spacing-medium);
  flex-direction: column;
  gap: var(--spacing-xsmall);
  background-color: var(--container-main-with-menu-bkg-default, #fff);
  border-radius: var(--borderRadius);
  box-shadow: var(--elevation-1);
  margin-top: var(--spacing-medium);
}

.searchCardsAndPagination--lre:not(.searchCardsAndPagination--lre__mobile) {
  @include respond-to-desktop() {
    display: flex;
  }
}

.searchCardsAndPagination--lre__mobile {
  display: none;
  margin: var(--spacing-medium);

  @include respond-to-large-tablet() {
    @include not-desktop() {
      display: flex;
    }
  }

  @include respond-to-tablet() {
    display: flex;
  }
}

// Only for 1200px breakpoint
@include respond-to-large-tablet {
  @include not-desktop {
    .searchCardsAndPagination--lre__large-tablet {
      background-color: var(--colors-secondary-weak);
      margin: 0 var(--spacing-medium);
      padding: var(--spacing-medium);
      border-bottom-left-radius: var(--radius-medium);
      border-bottom-right-radius: var(--radius-medium);

      .searchCardsAndPagination--lre {
        margin: 0;
      }
    }
  }
}
