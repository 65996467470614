@import '../../../responsive-nlc';

.searchForm {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: column;

    &.sticky {
      position: sticky;
      top: 0;
      background-color: var(--lcui-white);
      width: 100%;
      z-index: 10;
      opacity: 1;
      transform: translateY(0);
      transition:
        transform 0.65s linear,
        opacity 0.65s linear;
    }

    &__title {
      display: flex;
      gap: var(--spacing-xsmall);
      align-items: center;
      margin-top: var(--spacing-xsmall);
      margin-bottom: var(--spacing-medium);
    }

    &__divider {
      width: inherit;
    }

    &__title-filterPage {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__icon {
      rotate: 90deg;
    }

    &__icon-close {
      cursor: pointer;
      position: absolute;
      left: 17px;
    }
  }

  &__settings:not(:empty) {
    margin-bottom: var(--spacing-medium);

    &.filtersMode {
      margin: var(--spacing-medium);
    }

    .switch-label-filters {
      display: flex;
      flex-direction: column;

      @include respond-to-large-tablet() {
        margin-left: -2px;
      }

      &__tabs {
        gap: 0;
      }
    }
  }
}
