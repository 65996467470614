$button-size: 11px;

.number-filter {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &__input {
    align-items: center;
    display: flex;
    column-gap: 10px;
  }

  &__button {
    gap: 0;
    width: 8px;
    min-height: 8px !important;
  }

  &__value {
    width: 12px;
    user-select: none;
  }
}
