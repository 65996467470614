@import '../../../responsive-nlc';

.listingContainer {
  display: flex;

  @include respond-to-desktop {
    flex-direction: row;
    height: 100%;
  }

  @include not-desktop {
    flex-direction: column;
  }
}

.resultList {
  position: relative;
  flex-grow: 1;

  @include respond-to-large-tablet {
    padding-left: 16px;
    padding-right: 16px;
  }

  &__withAd {
    @include respond-to-desktop {
      width: calc(100% - 300px);
      max-width: 617px;
      flex-grow: 0;
    }

    .searchCardsAndPagination {
      background-color: var(--colors-secondary-weak);
      padding: var(--spacing-medium);
      height: calc(100% - 40px);

      @include respond-to-desktop {
        border-radius: var(--radius-medium);
      }

      @include respond-to-large-tablet {
        @include not-desktop {
          border-top-left-radius: var(--radius-medium);
          border-top-right-radius: var(--radius-medium);
        }
      }
    }
  }

  .searchCard {
    margin-bottom: 12px;
    position: relative;

    @include respond-to-mobile {
      width: 100%;
    }

    @include respond-to-tablet {
      @include not-mobile {
        flex-basis: 49%;
        width: 49%;
        min-width: 284px;
      }

      > * {
        margin: 0 auto;
      }
    }

    @include respond-to-large-tablet {
      height: 167px;
    }
  }
}

.searchCardContainer {
  display: flex;
  flex-direction: column;

  @include respond-to-tablet {
    @include not-mobile {
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }

  @include respond-to-mobile {
    flex-flow: column nowrap;
    align-items: center;
  }
}
