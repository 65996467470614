@import '../../responsive-nlc';

.equipementOption__searchList {
  display: flex;
  flex-direction: column;
  // row-gap: 10px;

  .dropdown {
    &__flexContainer {
      @include respond-to-large-tablet {
        display: flex;
        flex-wrap: wrap;

        > div {
          width: 50%;
        }
      }
    }

    &__element {
      margin: 6px 0;
      // margin-top: 12px;
      label {
        width: 100%;
      }
    }

    &__separator {
      margin: 4px auto;
    }

    &__items-separator {
      margin: 8px;
    }

    &__search-input {
      -webkit-appearance: none; // to hide default left icon on mobile ios
      background: transparent none; // to hide default background on mobile ios
      width: 100%;
      box-sizing: border-box;
      border: 1px solid var(--component-textfield-border-default, #d4d4de);
      border-radius: 5px;
      height: 40px;
      padding-left: 25px;
      background-image: url('../searchList/picto/search.svg');
      background-repeat: no-repeat;
      background-position: 5px;
      color: var(--component-textfield-text-completed, #2a292f);

      &.modal-home {
        font-size: 14px;
      }

      &::placeholder {
        font-size: 14px;
        color: var(--component-textfield-label-default, #6d6d83);
      }

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        display: inline-block;
        width: 10px;
        height: 10px;
        background-image: url('../searchList/picto/cross.svg');
        background-size: 100%;
        background-repeat: no-repeat;
      }

      &[disabled] {
        background-image: url('../searchList/picto/disabledSearch.svg');
        border: 1px solid var(--component-textfield-border-disabled, #d4d4de);

        &::placeholder {
          font-size: 14px;
          color: 1px solid var(--component-textfield-label-disabled, #c0c0ce);
        }
      }

      &:not([disabled]):hover,
      &:not([disabled]):focus,
      &-focus:not([disabled]) {
        border-color: var(--component-textfield-border-hover, #2a292f);
        outline: none;
      }

      &--equipment-option {
        margin-bottom: 12px;
        background-image: none;
        padding-left: 8px;
      }
    }

    &__input-container {
      width: 100%;
      margin-bottom: 8px;
    }

    &__autocomplete {
      &-container {
        z-index: 2;
        padding: 12px 8px 8px;
        background: var(--lcui-white);
        border-left: 1px solid var(--component-dropdown-border-default, #d4d4de);
        border-right: 1px solid var(--component-dropdown-border-default, #d4d4de);
        border-bottom: 1px solid var(--component-dropdown-border-default, #d4d4de);
        border-radius: 0 0 8px 8px;
        margin-top: -10px;

        @include respond-to-tablet {
          position: absolute;
          width: calc(100vw - 30px);
        }

        @include respond-to-large-tablet {
          position: relative;
        }

        &-focus {
          border-left: 1px solid var(--component-dropdown-border-active, #2a292f);
          border-right: 1px solid var(--component-dropdown-border-active, #2a292f);
          border-bottom: 1px solid var(--component-dropdown-border-active, #2a292f);
        }
      }

      &-option {
        margin-right: 8px;
        margin-left: 8px;
      }
    }

    &__option-added {
      margin-bottom: 8px;
    }

    &__itemsWrapper {
      overflow: auto;

      &::-webkit-scrollbar {
        background-color: var(--component-dropdown-border-default, #d4d4de);
        border-radius: 5px;
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--colors-secondary-xsoft, #a6a6ba);
        border-radius: 5px;
      }

      &__brand-name {
        text-align: left;
        margin: 6px 0 2px 8px;
      }

      &__model-name {
        padding-left: 16px;
      }
    }

    &__topItemsWrapper {
      &__placeholder {
        text-align: left;
      }

      > div:first-child {
        line-height: 16px;
        margin-bottom: 4px;
      }
    }
  }
}
